/*
    Values not used in the table:
    * brandTitle
    * endDate
    * performacePlatformId
    * startDate
    * styleLabel
*/

export const getTotalStyleThroughputQuery = `query GetTotalStyleThroughput($input: inputGetTotalThroughput!) {
	getTotalStyleThroughput(input: $input){
		throughput {
			avgCost
			styleTitle
			costDepleted
			throughput
			productCount
		}
    }
}`
