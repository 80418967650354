import type { InputGetTotalThroughput, } from "types"
import { useGetTotalStyleThroughputQuery, } from "../../../api"
import getFormattedStartAndEndDateStringsFromADateRange from "../../../utilities/getFormattedStartAndEndDateStringsFromADateRange"
import { usePPSub } from "../../ppSubscription"

/**
 * Custom hook to fetch throughput data by BA Style and date range.
 *
 * @param dateRange - Optional array of two Date objects representing the start and end dates.
 * @returns An object containing throughput data and various states of the data fetching process.
 *
 * @property data - The fetched BA Style throughput data.
 * @property isFetching - Boolean indicating if the data is currently being fetched.
 * @property refetch - Function to refetch the throughput data.
 * @property isLoading - Boolean indicating if the data is in the loading state.
 * @property isError - Boolean indicating if there was an error fetching the data.
 */

export const useThroughputByBAStyle = (dateRange?: Date[]) => {
    const { ppSubId, ppSubApiData } = usePPSub()
    const { startDate, endDate } = getFormattedStartAndEndDateStringsFromADateRange({
        dateRange,
        timezone: ppSubApiData?.settings?.timeZone
    })

    const inputData: InputGetTotalThroughput = {
        ppSubId,
        dateRange: {
            startDate,
            endDate
        }
    }

    const { data, isFetching, isLoading, isError, refetch } = useGetTotalStyleThroughputQuery(
        inputData,
        {
            skip: (dateRange && (!dateRange[0] || !dateRange[1])) || !ppSubId
        }
    )

    return {
        data,
        isFetching,
        isLoading,
        isError,
        refetch
    }
}
