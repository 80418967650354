export const searchBPBrandsQuery = /* GraphQL */ `
    query searchBPL($input: inputSearchBPL!) {
        searchBPL(input: $input) {  
        nextToken
        returnedCount
        totalResults
        items {
            __typename
            id  
            slug
            title
            ...on Brand {
                displayTitle
                description
                address {
                    street
                    city
                    state
                }
            }
            assets {
                __typename
                id
                alt
                ... on S3Asset {
                    path
                }
                ... on CloudinaryAsset {
                    cloudinaryId
                }
            }
            
        }
    }
        }
`
