/*
    Values not used in the table:
    * endDate
    * performacePlatformId
    * productId
    * productType
    * startDate
    * styleLabel
*/

export const getTotalProductThroughput = /* GraphQL */ `query GetTotalProductThroughput($input: inputGetTotalThroughput!) {
	getTotalProductThroughput(input: $input){
		throughput {
			abv
			avgCost
			brandTitle
			costDepleted
			productTitle
			styleTitle
			throughput
		}
	}
}`
