/*
    Values not used in the table:
    * abvGroup
    * endDate
    * performacePlatformId
    * startDate
*/

export const getTotalAbvThroughputQuery = `query GetTotalAbvThroughput($input: inputGetTotalThroughput!) {
	getTotalAbvThroughput(input: $input){
		throughput {
			avgCost
			costDepleted
			productCount
			throughput
			topStyle
		}
    }
}`
