/*
    Values not used in the table:
    * endDate
    * performacePlatformId
    * startDate
*/

export const getTotalBlxStyleThroughputQuery = `query GetTotalBlxStyleThroughput($input: inputGetTotalThroughput!) {
	getTotalBlxStyleThroughput(input: $input){
		throughput {
			avgCost
			blxGroup
			costDepleted
			productCount
			throughput
		}
	}
}`