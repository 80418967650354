import { toast } from "sonner"
import type {
    InputGetThroughputByKegId,
    InputGetThroughputByPpSub,
    InputGetThroughputByProduct,
    InputGetTotalThroughput,
    KegThroughput,
    PpSubThroughputDetails,
    PpSubThroughputSummary,
    ProductThroughput,
    ProductThroughputDetail,
    TotalAbvThroughput,
    TotalBlxStyleThroughput,
    TotalPlatformThroughput,
    TotalProductThroughput,
    TotalStyleThroughput,
    TotalTapThroughput
} from "types"
import { getThroughputByKegId } from "../features/throughput/queries/getThroughputByKegId"
import { getThroughputByProduct } from "../features/throughput/queries/getThroughputByProduct"
import { getThroughputDetailsByPPSub } from "../features/throughput/queries/getThroughputDetailsByPPSub"
import { getThroughputSummaryByPPSub } from "../features/throughput/queries/getThroughputSummaryByPPSub"
import { getTotalAbvThroughputQuery } from "../features/throughput/queries/getTotalAbvThroughputQuery"
import { getTotalBlxStyleThroughputQuery } from "../features/throughput/queries/getTotalBlxStyleThroughputQuery"
import { getTotalPlatformThroughputQuery } from "../features/throughput/queries/getTotalPlatformThroughputQuery"
import { getTotalProductThroughput } from "../features/throughput/queries/getTotalProductThroughput"
import { getTotalStyleThroughputQuery } from "../features/throughput/queries/getTotalStyleThroughputQuery"
import { getTotalTapThroughput } from "../features/throughput/queries/getTotalTapThroughput"
import { baseApi } from "./baseApi"

const throughPutApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getThroughputByProduct: builder.query<
            ProductThroughputDetail[],
            InputGetThroughputByProduct
        >({
            providesTags: ["ThroughPutByProduct", "throughput"],
            query: (input) => ({
                query: getThroughputByProduct,
                args: { ...input }
            }),
            transformResponse: (data: ProductThroughput): ProductThroughputDetail[] => {
                // TODO: Add notifications
                return data?.productThroughput || []
            },
            transformErrorResponse: () =>
                toast.error("There was an issue getting the throughput by product")
        }),
        getThroughputByKegId: builder.query<KegThroughput, InputGetThroughputByKegId>({
            providesTags: ["ThroughputByKegId"],
            query: (input) => ({
                query: getThroughputByKegId,
                args: { ...input }
            }),
            transformErrorResponse: () =>
                toast.error("There was an issue getting the throughput by keg id")
        }),
        getThroughputDetailsByPPSub: builder.query<
            PpSubThroughputDetails,
            InputGetThroughputByPpSub
        >({
            query: (input) => ({
                query: getThroughputDetailsByPPSub,
                args: input
            })
        }),
        getThroughputSummaryByPPSub: builder.query<
            PpSubThroughputSummary,
            InputGetThroughputByPpSub
        >({
            query: (input) => ({
                query: getThroughputSummaryByPPSub,
                args: input
            })
        }),
        // Total throughput queries - Isolated throughput table queries
        getTotalProductThroughput: builder.query<TotalProductThroughput, InputGetTotalThroughput>({
            query: (input) => ({
                query: getTotalProductThroughput,
                args: input
            })
        }),
        getTotalTapThroughput: builder.query<TotalTapThroughput, InputGetTotalThroughput>({
            query: (input) => ({
                query: getTotalTapThroughput,
                args: input
            })
        }),
        getTotalAbvThroughput: builder.query<TotalAbvThroughput, InputGetTotalThroughput>({
            query: (input) => ({
                query: getTotalAbvThroughputQuery,
                args: input
            })
        }),
        getTotalBlxStyleThroughput: builder.query<TotalBlxStyleThroughput, InputGetTotalThroughput>({
            query: (input) => ({
                query: getTotalBlxStyleThroughputQuery,
                args: input
            })
        }),
        getTotalPlatformThroughput: builder.query<TotalPlatformThroughput, InputGetTotalThroughput>({
            query: (input) => ({
                query: getTotalPlatformThroughputQuery,
                args: input
            })
        }),
        getTotalStyleThroughput: builder.query<TotalStyleThroughput, InputGetTotalThroughput>({
            query: (input) => ({
                query: getTotalStyleThroughputQuery,
                args: input
            })
        })
    })
})

const {
    useGetThroughputByKegIdQuery,
    useGetThroughputByProductQuery,
    useGetThroughputDetailsByPPSubQuery,
    useGetThroughputSummaryByPPSubQuery,
    useGetTotalAbvThroughputQuery,
    useGetTotalBlxStyleThroughputQuery,
    useGetTotalPlatformThroughputQuery,
    useGetTotalProductThroughputQuery,
    useGetTotalStyleThroughputQuery,
    useGetTotalTapThroughputQuery,
} = throughPutApi

export {
    throughPutApi,
    useGetThroughputByKegIdQuery,
    useGetThroughputByProductQuery,
    useGetThroughputDetailsByPPSubQuery,
    useGetThroughputSummaryByPPSubQuery,
    useGetTotalAbvThroughputQuery,
    useGetTotalBlxStyleThroughputQuery,
    useGetTotalPlatformThroughputQuery,
    useGetTotalProductThroughputQuery,
    useGetTotalStyleThroughputQuery,
    useGetTotalTapThroughputQuery,
}
