/*
    Values not used in the table:
    * endDate
    * performacePlatformId
    * startDate
*/

export const getTotalPlatformThroughputQuery = `query GetTotalPlatformThroughput($input: inputGetTotalThroughput!) {
	getTotalPlatformThroughput(input: $input){
		throughput {
			avgCost
			costDepleted
			depletionDate
			productCount
			throughput
		}
    }
}`
