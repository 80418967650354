/*
    Values not used in the table:
    * endDate
    * performacePlatformId
    * startDate
    * tapsensorid
*/

export const getTotalTapThroughput = /* GraphQL */ `query GetTotalTapThroughput($input: inputGetTotalThroughput!) {
	getTotalTapThroughput(input: $input){
		throughput {
			avgCost
			costDepleted
			productCount
			tapNumber
			throughput
		}
	}
}`
